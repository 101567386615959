import axios from 'axios';

const AUTH_KEY = 'LOGIN_AUTH';

export const get = ({ url, controller, auth }) => {
    return new Promise((resolve, reject) => {
        const options = {
            url,
            method: 'get',
            signal: controller.signal
        }
        if (auth) {
            const data = localStorage.getItem(AUTH_KEY);
            const json = JSON.parse(data);
            options.auth = {
                'Authorization': `Bearer ${json.token}`
            }
        }
        axios(options).then(function (response) {
            return resolve(response);
        }).catch(function (err) {
            return reject(err);
        });
    })
}

export const post = ({ url, data, controller, auth }) => {
    return new Promise((resolve, reject) => {
        const options = {
            url,
            data,
            method: 'post',
            signal: controller.signal
        }
        if (auth) {
            const json = JSON.parse(localStorage.getItem(AUTH_KEY));
            options.auth = {
                'Authorization': `Bearer ${json.token}`
            }
        }
        axios(options).then(function (response) {
            return resolve(response);
        }).catch(function (err) {
            return reject(err);
        });
    })
}

export const makeFormData = (form) => {
    const data = new FormData();  
    for (let j in form) {
        const item = form[j];
        if (Array.isArray(item)) {
            for (let k in item) {
                for (let l in item[k]) {
                    data.append(`items[${k}][${l}]`, item[k][l]);
                }
            }
        }
        else {
            data.append(j, item);
        }
    }
    return data;
}