import { atom } from 'recoil';

export const dashboardState = atom({
    key: 'dashboardState',
    default: {
        cpu: 'Intel',
        fromPrice: 0,
        toPrice: 0,
        maxPrice: 25000000,
        submit: false,
        selectedGames: [],
        games: [] // submitted games
    }
});

export const pcBuilderState = atom({
    key: 'pcBuilderState',
    default: {
        activeCategory: null,
        categories: null,
        groupCategory: '',
        selectedLft: null,
        selectedRgt: null,
        notify: false
    }
});

export const orderState = atom({
    key: 'orderState',
    default: {
        store: null,
        user_name: null,
        mobile_number: null,
        email: null
    }
});

export const alertState = atom({
    key: 'alertState',
    content: null,
    default: true
});

export const settingState = atom({
    key: 'settingState',
    content: null,
    default: true
});