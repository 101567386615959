import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { alertState } from '../../states/global_states';
import { useTranslation } from 'react-i18next';

function AlertWidget() {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [state, setState] = useRecoilState(alertState);
    const [style, setStyle] = useState({
        top: 0,
        left: 0,
        opacity: 0
    });

    const onClose = () => {
        setState(state => {
            return {
                ...state,
                content: null
            }
        });
    }

    useEffect(() => {
        if (state.content && ref.current) {
            const w = ref.current.offsetWidth;
            const h = ref.current.offsetHeight;
            const winW = window.innerWidth;
            const winH = window.innerHeight;
            const left = winW / 2 - w / 2;
            const top = winH / 2 - h / 2;
            setStyle({
                left,
                top,
                opacity: 1,
            });
        }
    }, [state.content]);

    if (!state.content) {
        return null;
    }

    return (
        <>
            <div
                ref={ref}
                className='bg-white min-w-[100px] min-h-[30px] z-50 fixed transition-opacity px-6 pt-8 pb-4'
                style={style}
            >
                <div className='mb-6'>{state.content}</div>
                <div className='flex justify-end'>
                    <button onClick={onClose} className='p-3 bg-red-600 text-red-50 hover:bg-red-700 hover:text-white transition-colors'>{t('Close')}</button>
                </div>
            </div>
            <div className='absolute h-full h-full bg-gray-900 opacity-50 z-40 inset-0'></div>
        </>
    );
}

export default AlertWidget;