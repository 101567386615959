
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     // the translations
//     // (tip move them in a JSON file and import them,
//     // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
//     resources: {
//       en: {
//         translation: {
//           'Welcome to React': 'Welcome to React and react-i18next'
//         }
//       }
//     },
//     lng: 'en', // if you're using a language detector, do not define the lng option
//     fallbackLng: 'en',

//     interpolation: {
//       escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     }
//   });

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Motto": "Custom build to order. Our PC builder lets you choose the components you need to outclass your adversaries."
    }
  },
  id: {
    translation: {
      "Motto": "PC custom yang terbaik untuk anda",
      "Pick Top Games": "Pilih Top Games",
      "Set Your Budget": "Tentukan Budget Anda",
      "Month": "Bulan",
      "Pick Your CPU": "Pilih CPU Anda",
      "Costumize": "Kostumisasi",
      "Starting at": "Mulai dari",
      'Add to Cart': 'Tambah ke Keranjang',
      'Base Components': 'Komponen Dasar',
      'Accessories': 'Aksesories',
      'Search for Component': 'Cari komponen',
      'Cart': 'Keranjang',
      'Our Store': 'Toko Kami',
      'Bundling': 'Paket PC',
      'PC Builder': 'Rakit PC',
      'Navigation': 'Navigasi',
      'Buy': 'Beli',
      'Summary': 'Rangkuman',
      'Bundling Summary': 'Rangkuman Pesanan',
      'Order Detail': 'Detail Pemesanan',
      'Terms & Condition': 'Syarat dan Ketentuan',
      'Complete Order': 'Selesaikan Pesanan',
      'Store Pickup': 'Toko Pengambilan Barang',
      'Name': 'Nama',
      'Mobile Number': 'Nomor Telepon',
      'Save': 'Simpan',
      'Detail Specification': 'Spesifikasi Detail',
      'Field is mandatory': 'Kolom wajib diisi',
      'No records found': 'Data tidak ditemukan',
      'Order Summary': 'Rangkuman Pesanan',
      'Last update': 'Update terakhir',
      'Store': 'Toko',
      'Order Now': 'Pesan Sekarang',
      'Page': 'Hal',
      'of': 'dari'
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;