import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import TopBarWidget from './top_bar_widget';
import { formatNumber } from '../../helper/formatter';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pcBuilderState, settingState } from '../../states/global_states';
import AlertWidget from '../shared/alert_widget';
import { get } from '../../helper/http_helper';
import config from '../../config.json';
import ImageWidget from './image_widget';


function Layout() {
    const [display] = useState({
        minHeight: window.innerHeight - 100
    })
    const [year, setYear] = useState();
    const { t } = useTranslation();
    const [cart, setCart] = useState(null);
    const pcBuilder = useRecoilValue(pcBuilderState);
    const abortRef = useRef(new AbortController());
    const [sosmed, setSosmed] = useState([]);
    const [poweredBy, setPoweredBy] = useState([]);
    const [chatNumber, setChatNumber] = useState(null);
    const setSetting = useSetRecoilState(settingState);

    const getTotal = useCallback(() => {
        let total = 0;
        if (cart) {
            for (let j in cart.items) {
                const model = cart.items[j];
                const price = model.bundling_price ? model.bundling_price : model.price;
                total += price * model.qty;
            }
        }
        return total;
    }, [cart]);

    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);

    useEffect(() => {
        const json = localStorage.getItem('cart');
        const cart = json ? JSON.parse(json) : null;
        setCart(cart);
    }, [pcBuilder.notify]);

    useEffect(() => {
        get({
            url: `${config.API_URL}/client/get-settings`,
            controller: abortRef.current,
        }).then(({ data: { powered_by, settings, sosmed} }) => {
            setSosmed(sosmed);
            setPoweredBy(powered_by);
            setSetting(settings);            
            setChatNumber(settings.chat_number);
        });
    }, [setSetting]);

    return (
        <div className='flex flex-col relative text-gray-500 bg-[#F8F8F8]'>
            <AlertWidget />
            <div className='border-b-4 border-red-500'>
                <div className='px-4 lg:w-[1024px] m-auto'>
                    <TopBarWidget />
                </div>
            </div>
            {cart &&
                <div className='bg-white shadow-lg sticky top-0 z-20'>
                    <div className='px-4 py-4 lg:w-[1024px] m-auto font-semibold'>
                        <div className='lg:hidden'>
                            <div className='mr-4 mb-4 text-2xl'>IDR {formatNumber(getTotal())}</div>
                            <div className='flex w-full'>
                                <Link to={'/summary'} className='w-1/2 p-4 bg-red-600 text-red-50 hover:bg-red-700 hover:text-white transition-colors text-center uppercase'>
                                    {t('Summary')}
                                </Link>
                                <Link to={'/pc-builder'} className='w-1/2 p-4 bg-red-600 text-red-50 hover:bg-red-700 hover:text-white transition-colors text-center uppercase'>
                                    {t('Cart')}
                                </Link>
                            </div>
                        </div>
                        <div className='hidden lg:flex justify-between py-4'>
                            <div className='grow'>
                                <Link to={'/summary'} className='p-4 bg-red-600 text-red-50 hover:bg-red-700 hover:text-white transition-colors text-center uppercase'>
                                    {t('Summary')}
                                </Link>
                            </div>
                            <div className='flex items-center'>
                                <div className='mr-4'>IDR {formatNumber(getTotal())}</div>
                                <div>
                                    <Link to={'/pc-builder'} className='p-4 bg-red-600 text-red-50 hover:bg-red-700 hover:text-white transition-colors text-center uppercase'>
                                        {t('Cart')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className='px-4 lg:w-[1024px] w-full m-auto relative grow'
                style={{
                    minHeight: display.minHeight
                }}>
                <Outlet />
            </div>
            <div>
                <div className='px-8 py-8 lg:w-[1024px] m-auto'>
                    <h3 className='text-xs mb-6'>GSSHOP PC BUILDER IS POWERED BY :</h3>
                    <ul className='flex items-center'>
                        {poweredBy.map((item, key) => {
                            return <li key={key} className='mr-4'>
                                <ImageWidget
                                    src={`${config.API_URL}/image/${item.file_id}`}
                                    alt={item.label}
                                    className='max-h-[64px]'
                                />
                            </li>
                        })}
                    </ul>
                </div>
            </div>
            <div className='bg-red-600 text-red-50'>
                <div className='px-4 py-6 lg:w-[1024px] m-auto flex justify-between items-center text-sm'>
                    <div>@{year} GSSHOP PC BUILDER</div>
                    <div>
                        <ul className='flex'>
                            {sosmed.map((item, key) => {
                                return <li key={key} className='pl-6'>
                                    <Link to={item.link} target='_blank' title={item.label}>
                                        <ImageWidget
                                            src={`${config.API_URL}/image/${item.file_id}`}
                                            alt={item.label}
                                            className='w-[22px] h-[22px]'
                                        />
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fixed inset-x-0 bottom-0 p-4">
                <div className='flex justify-end'>
                    <Link className='font-semibold text-red-500 hover:text-red-700 transition-colors'
                     to={`https://api.whatsapp.com/send?phone=${chatNumber}&text=Halo%20saya%20mau%20konfirmasi`}>
                        <img alt={t('Logo')} src={`logo/whatsapp.png`} width={80} />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Layout;
