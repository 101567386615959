import { useEffect, useState } from 'react';
import Spinner from '../shared/spinner';
import config from '../../config.json';

function ImageWidget({ src, alt, ...rest }) {
    const [imgSrc, setImgSrc] = useState(null);

    useEffect(() => {
        let img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
        img.onerror = () => {
            setImgSrc(config.BASE_URL + '/logo/image.png');
        }
        img = null;
    }, [src]);

    if (imgSrc === null) {
        return <Spinner />
    }
    else {
        return <img src={imgSrc} alt={alt ?? 'Image'} {...rest} />
    }
}

ImageWidget.defaultProps = {
    size: 32,
    alt: 'Thumbnail'
}

export default ImageWidget;