import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const menu = [
    {
        label: 'PC Builder',
        path: '/pc-builder'
    },
    {
        label: 'Bundling',
        path: '/bundling'
    },
    {
        label: 'Store',
        path: '/store'
    },
    {
        label: 'FAQ',
        path: '/faq'
    }
]

function TopBarWidget() {
    const { i18n, t } = useTranslation();
    const [lang, setLang] = useState('en');
    const switchLang = () => setLang(state => state === 'en' ? 'id' : 'en');

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    return (
        <>
            <div className='py-4 flex justify-between'>
                <div className='flex'>
                    <Link to={'/'}>
                        <img alt={t('Logo')} src={`logo/gsshop-logo.png`} width={180} />
                    </Link>
                    <ul className='hidden lg:flex items-center font-semibold text-xl'>
                        {menu.map(({ path, label }, idx) => {
                            return <li key={idx}>
                                <Link to={path} className='p-8'>{t(label)}</Link>
                            </li>
                        })}
                    </ul>
                </div>
                <button
                    onClick={switchLang}>
                    <img alt='Flag' src={`/icons/${lang}.png`} />
                </button>
            </div>
            <div className='lg:hidden flex justify-center'>
                <ul className='flex text-lg font-semibold'>
                    {menu.map(({ path, label }, idx) => {
                        return <li key={idx}>
                            <Link to={path} className='block py-4 px-8'>{t(label)}</Link>
                        </li>
                    })}
                </ul>
            </div>
        </>
    );
}

export default TopBarWidget;