import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Layout from './pages/shared/layout';
import React, { Suspense } from 'react';
import NotFoundPage from './pages/shared/not_found_page';
import Spinner from './pages/shared/spinner';

const routes = [
    {
        path: '/',
        element: React.lazy(() => import('./pages/dashboard/dashboard_page'))
    },
    {
        path: '/pc-builder/:id?',
        element: React.lazy(() => import('./pages/pc-builder/pc_builder_page'))
    },
    {
        path: '/product/:id/:permalink?',
        element: React.lazy(() => import('./pages/product/product_page'))
    },
    {
        path: '/bundling',
        element: React.lazy(() => import('./pages/bundling/bundling_page'))
    },
    {
        path: '/summary',
        element: React.lazy(() => import('./pages/summary/summary_page'))
    },
    {
        path: '/store',
        element: React.lazy(() => import('./pages/store/store_page'))
    },
    {
        path: '/checkout',
        element: React.lazy(() => import('./pages/checkout/checkout_page'))
    },
    {
        path: '/faq',
        element: React.lazy(() => import('./pages/faq/faq_page'))
    }
];

const router = createBrowserRouter(createRoutesFromElements(
    <Route path='/' element={<Layout />}>
        <Route path='*' element={<NotFoundPage />} />
        {routes.map(({ path, element: Element }, key) => {
            return <Route
                key={key}
                path={path}
                element={
                    <Suspense fallback={
                        <div className='absolute w-full h-full flex items-center justify-center'>
                            <Spinner color='#ccc' />
                        </div>
                    }>
                        <Element />
                    </Suspense>
                } />;
        })}
    </Route>
));

function App() {
    return (<RouterProvider router={router} />);
}

export default App;