import format from "number-format.js";

export const formatNumber = (val) => {
    return format(`#,##0.##`, val);
}

export const truncate = (text, len) => {
    if (text.length < len) return text;
    const val = text.substring(0, len);
    const idx = val.lastIndexOf(' ');
    if (idx === -1) return val;
    return val.substring(0, idx);
}